import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Layout from "../components/layout"
import SEOComponent from "../components/seo"
import { Hero } from "../components/hero"
import PageCTA from "../components/pageCTA"

export const query = graphql`
  query PageWithSideImageQuery($id: String) {
    contentfulPageWithSideImage(id: {eq: $id}) {
      id
      title
      description
      bannerImage {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      header
      sideImage {
        gatsbyImageData
      }
      sideImageAltText
      imageSide
      pageContent {
        raw
      }
      pageCta {
        header
        buttonText
        destination
        content {
          raw
        }
      }
    }
  }
`

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p className="text-lg sm:text-xl text-gray-500 leading-relaxed">{children}</p>,
    [BLOCKS.HEADING_2]: (node, children) => <h2 className="text-3xl">{children}</h2>,
    [BLOCKS.UL_LIST]: (node, children) => <ul className="list-inside list-disc text-gray-500 text-lg ml-4">{children}</ul>,
    [BLOCKS.OL_LIST]: (node, children) => <ol  className="list-inside list-decimal text-gray-500 text-lg space-y-2">{children}</ol>,
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.includes("http")) {
        return (
          <a href={node.data.uri}>{children}</a>
        )
      } else {
        return (
          <Link to={node.data.uri}>{children}</Link>
        )
      }
    },
    [BLOCKS.LIST_ITEM]: (node, children) => {
      const transformedChildren = documentToReactComponents(node, {
        renderNode: {
          [BLOCKS.PARAGRAPH]: (node, children) => children,
          [BLOCKS.LIST_ITEM]: (node, children) => children,
          [INLINES.HYPERLINK]: (node, children) => {
            if (node.data.uri.includes("http")) {
              return (
                <a href={node.data.uri}>{children}</a>
                )
            } else {
              return (
                <Link to={node.data.uri}>{children}</Link>
              )
            }
          },
        },
      });
      return <li>{transformedChildren}</li>;
    }
  },
}
const optionsPageCta = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p className="font-serif text-gray-500 text-xl">{children}</p>,
    [INLINES.HYPERLINK]: (node, children) => <Link to={node.data.uri} className="text-purple-500 hover:text-blue-700 hover:underline">{children}</Link>
  },
}

const PageWithSideImage = ({ data }) => {
  const banner = data.contentfulPageWithSideImage.bannerImage.gatsbyImageData
  const sideImage = data.contentfulPageWithSideImage.sideImage.gatsbyImageData
  const { title, description, header, sideImageAltText, pageContent, pageCta, imageSide } = data?.contentfulPageWithSideImage

  return (
    <Layout>
      <SEOComponent title={title} description={description} />
      <Hero image={banner} header={header} />
      <section className="container my-4 mx-auto pt-4 sm:px-24 px-4 pb-6">
        <div className="grid gap-4 sm:grid-cols-3">
          <div className={`sm:col-span-1 mx-auto ${imageSide === "right" ? "order-last" : ""}`}>
            <GatsbyImage image={sideImage} alt={sideImageAltText} />
          </div>
          <div className="font-serif sm:col-span-2 space-y-2">
            {renderRichText(pageContent, options)}
          </div>
        </div>
      </section>
      {pageCta && (
        <PageCTA
          h2={pageCta.header}
          buttonText={pageCta.buttonText}
          destination={pageCta.destination}
        >
          {renderRichText(pageCta.content, optionsPageCta)}
        </PageCTA>
      )}
    </Layout>
  )
}

export default PageWithSideImage
